.sec-bg {
  /* background-image: linear-gradient(to right, rgba(0, 0, 0, 0.226), rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.486)), url("https://res.cloudinary.com/dogqbjx8a/image/upload/rear-view-programmer-working-all-night-long_cbb94a.webp"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 5;
}

body {
  /* font-family: 'Play', sans-serif; */
  color: white;
  /* background-image: ; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  display: none;
}

*::-moz-progress-bar {
  display: none;
}

.glass-card {
  backdrop-filter: blur(7px) saturate(200%);
  -webkit-backdrop-filter: blur(7px) saturate(200%);
  background-color: rgba(56, 56, 56, 0.66);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.only-show-on-large-screen {
  display: block;
}

.only-show-on-small-screen {
  display: none;
}



@keyframes svgAnimation {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

/* For screen below 950px */
@media screen and (max-width: 960px) {

  .only-show-on-large-screen {
    display: none;
  }

  .only-show-on-small-screen {
    display: block;
  }
}

.i {
  position: absolute;
  z-index: 1000;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #fa6dff;
  pointer-events: none;
  animation: animate 2s linear forwards;
}

@keyframes animate {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }

  100% {
    opacity: 0;
    transform: translate(var(--x), var(--y));
  }
}


.rotating-text {
  -webkit-text-stroke: 1px #ffffff;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

.timer-text {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.wmde-markdown {
  background: transparent !important;
  color:rgba(255, 255, 255, 0.76) !important;
}

.timer {
  font-family: 'Orbitron', sans-serif;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

.animate-glow {
  animation: glow 1s ease-in-out infinite alternate;
}
